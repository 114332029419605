div[data-is="vd-block-accordion"] {
    > .block-section.accordion {
        width: 100%;
    }

}
.vd-product-thumb {
    
    overflow: auto;

    margin-bottom: 20px;

    border: 1px solid #ddd;
    h4 {
        font-weight: bold;
    }
    .caption {
        min-height: 180px;

        padding: 0 20px;
    }
    .image {
        float: none;

        text-align: center;
        a {
            display: block;
            &:hover {
                opacity: 0.8;
            }
        }
        img {
            margin-right: auto;

            margin-left: auto;
        }
    }
    .rating {
        padding-bottom: 10px;
        .fa-stack {
            font-size: 8px;
        }
        .fa-star-o {
            color: #999;

            font-size: 15px;
        }
        .fa-star {
            color: #FC0;

            font-size: 15px;
        }
        .fa-star + .fa-star-o {
            color: #E69500;
        }
    }
    h2.price {
        margin: 0;
    }
    .price {
        color: #444;
        
        .price-new {
            font-weight: 600;
        }
        
        .price-old {
            margin-left: 10px;

            text-decoration: line-through;

            color: #999;
        }
        
        .price-tax {
            display: block;

            color: #999;

            font-size: 12px;
        }
    }
    
    .button-group {
        overflow: auto;

        border-top: 1px solid #ddd;
        background-color: #eee;
        button {
            display: inline-block;
            float: left;

            width: 60%;

            text-align: center;
            text-transform: uppercase;

            color: #888;
            border: none;
            background-color: #eee;

            font-weight: bold;
            line-height: 38px;
            >span{
                display: inline!important;
            }
            & + button {
                width: 20%;

                border-left: 1px solid #ddd;
            }
            &:hover {
                cursor: pointer;
                text-decoration: none;

                color: #444;
                background-color: #ddd;
            }
        }
    }
}
.tour-content.tour-content.tour-content.block-content.child{
    position: relative;

    display: flex;

    flex-flow: row;

    flex-wrap: nowrap;

    padding: 0!important;
    > .control{
        padding-bottom: 10px;
    }
    > ul.vd-tour-list{
        float:left;

        min-height: 112px;
        margin: 0;
        padding: 0;

        flex: 0 0 202px;
        
        > li{
            position: relative;
            top: 0;

            float: left;
            clear:left;

            width: 100%;
            margin: 0 0 1px 0;
            padding: 0 .1em 0 0;

            list-style: none;

            white-space: nowrap;

            color: #fff;
            border-right-width: 0 !important;
            border-bottom-width: 1px !important;
            border-radius: 5px 0 0 5px;
            background: #4c5866;

            font-weight: normal;
            &.tab-control{
                >a{
                    border: 0;
                    &:first-child{
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }
                }
            }
            &.active{
                background: #F8F8F8;

                font-weight: normal;
                
                > a{
                    color:#4c5866;
                    &.vd-btn{
                        display: block!important;
                    }
                }
            }
            &:not(.tab-control) {
                > a{
                    &.vd-btn{
                        display: none;

                        width: 40px;
                        height: 40px;
                    }
                }
            }
            > a{
                display: block;
                float:left;

                padding:  14px 20px;

                cursor: pointer;
                text-decoration: none;

                color: #fff;

                font-size: 13px;
                line-height: 15.6px;
            }
        }
    }
    > .vd-tour-wrapper{
        width: calc(100% - 12em);
        padding: 1px;
        float:right;
        display: block;
        > .block-section{
            border-width: 0;
            background: #F8F8F8;
        }
    }
}
.tabs-content{
    position: relative;

    padding: .2em;
    > ul.vd-tabs-list.vd-tabs-list.vd-tabs-list{
        margin: 0;
        padding: 0;
        
        &:after{
            display:table;
            clear:both;

            content:'';
        }
        &.vd-align-center{
            text-align: center;
        }
        &.vd-align-left{
            text-align: left;
        }
        &.vd-align-right{
            text-align: right;
        }
        
        > li{
            position: relative;
            top: 0;

            float: left;

            margin: 1px 1px 0 0;

            list-style: none;

            white-space: nowrap;

            color: #fff;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            background: #4c5866;

            font-weight: normal;
            &.tab-control{
                >a{
                    border: 0;
                    &:first-child{
                        border-top-left-radius: 5px;
                    }
                    &:last-child{
                        border-top-right-radius: 5px;
                    }
                }
            }
            &.active{
                font-weight: normal;
                > a{
                    color: #000;

                    background-color: #F8F8F8;
                    &.vd-btn{
                        display: block!important;
                    }
                }
            }
            &:not(.tab-control) {
                > a{
                    &.vd-btn{
                        display: none;

                        width: 40px;
                        height: 40px;
                    }
                }
            }
            > a{
                display: block;
                float:left;

                padding: 10px 14px;

                cursor: pointer;
                text-decoration: none;

                color: #fff;

                font-size: 13px;
                
            }
        }
    }
    [data-is="vd-block-tabs"] {
        width: 100%;
        .block-section{
            display: block;
    
            margin-top: 0;
    
            color: #333333;
            background: #F8F8F8;
        }
    }
}
.vd-search{
    .input-lg {
        height: 45px;
        padding: 0 10px;

        line-height: 20px;
    }
    .btn-lg{
        padding: 10px 35px;

        text-shadow: 0 1px 0 #FFF;

        font-size: 15px;
        line-height: 23px;
    }
}
.wrapper-video{
    position: relative;

    width: 100%;
    &.vd-video-aspect-ration-169{
        padding-top: 56.25%;
    }
    &.vd-video-aspect-ration-43{
        padding-top: 75%;
    }
    &.vd-video-aspect-ration-235{
        padding-top: 42.55319149%;
    }
    iframe{
        position: absolute;
        top: 0;
        left: 0;

        display: block;

        box-sizing: border-box;
        width: 100%;
        height: 100%;
        margin: 0;
    }
}
