@import "core/mixin.scss";
@import "core/bootstrap-grid.scss";
@import "core/component.scss";
@import "core/animate.scss";

@font-face {
    font-family: 'Glyph';
    src: url('fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('fonts/glyphicons-halflings-regular.woff') format('woff');
}

.vd {
    .no-padding{
        padding: 0!important;
    }
}

.vd-frontend{
    position: relative;
    &:hover {
        .vd-btn-frontend-edit.btn-group-xs.btn-edit{
            opacity: 1;
            visibility: visible;
        }
    }
    #sortable{
        .no-padding{
            padding: 0!important;
        }
    }

    .vd-btn-frontend-edit.btn-group-xs.btn-edit{
        z-index: 2;
        position: absolute;
        top:0;
        left:0;
        opacity: 0;
        visibility: hidden;
        > .btn.btn-default{
            padding: 1px 5px;
            font-size: 12px;
            line-height: 1.5;
            border-radius: 3px;
            height: auto;
            color: #777;
            text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
            background: #e7e7e7 linear-gradient(to bottom, #eeeeee, #dddddd) repeat-x;
            border-color: #dddddd #dddddd #b3b3b3 #b7b7b7;
            font-weight: 400;
            font-family: "Open Sans", sans-serif;
            > i {
                font-weight: 400;
            }
        }

    }
    &.visual-designer {
        z-index: 1;
    }
}


#tab-description{
    overflow: visible;
    .vd{
        .glyphicon {
            font-family: 'Glyph', sans-serif;
        }
        div,p{
            margin-bottom: 0;
        }
    }
}
.block-container{
    padding: 0;
    //TODO unknown property
    //z-index:10;

    &:not(.show_mobile) {
        @media only screen and (max-width: 767px) {
            display: none;
        }
    }

    &:not(.show_tablet) {
        @media only screen and (min-width: 768px) and (max-width: 992px) {
            display: none;
        }
    }

    &:not(.show_desktop) {
        @media only screen and (min-width: 992px) {
            display: none;
        }
    }

    .glyphicon {
        font-family: 'Glyph', sans-serif;
    }
    &.video{
        position: relative;
        > .block-content{
            background-color:transparent;
            > .video-background{
                height: 100%;
                overflow: hidden;
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                z-index: -1;
            }
        }
    }
    &[class*=col-]{
        &:not(.product-layout){
            padding: 0;
        }
    }
    &:after{
        display:table;
        content:'';
        clear:both;
    }
}
.sharrre{
    .buttons{
        margin:0;
        .button{
            max-width:inherit;
            padding:inherit;
            margin:inherit;
            background:inherit;
        }
    }
}

.block-section {

    &:not(.accordion) {
        display: none;
        &.active {
            display: block;
        }
    }

    &.accordion {

        display: block;

        margin-top: 0;
        margin-bottom: 2px;

        color: #333333;
        background: #ffffff;
        &:not(.active) {
            max-height: 43px;
            min-height: 43px;
        }
        &.active {
            > .vd-panel-heading {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        > .vd-panel-heading {
            box-sizing: border-box;

            cursor: pointer;
            -webkit-transition: background .2s ease-in-out;
            transition: background .2s ease-in-out;
            background-color: #4c5866;
            text-align: left;

            border: 0 solid transparent;
            border-radius: 5px;
            height: 43px;
            margin-bottom: 0 !important;

            > h4.vd-panel-title {
                margin: 0;
                padding: 0;

                line-height: 1;

                > a.section-heading {
                    position: relative;

                    display: block;

                    box-sizing: border-box;
                    padding: 14px 20px;

                    -webkit-transition: color .2s ease-in-out;
                    transition: color .2s ease-in-out;
                    text-decoration: none;

                    line-height: 1;
                    color: #fff;
                    border: none;
                    background: 0 0;

                    > .title {
                        padding-left: 20px;

                        color: #fff;
                        font-size: 15px;
                        line-height: 15px;
                        margin: 0;
                    }
                    > i {
                        color: #fff;
                        position: absolute;
                        top: 50%;
                        left: 20px;

                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }
                }
            }
        }
        &.active {
            > .vd-panel-body {
                display: block;
            }
        }
        > .vd-panel-body {
            border: 1px solid #4c5866;
            display: none;

            min-height: 65px;

            &:after{
                display: table;
                content: '';
                clear: both;
            }

            & > top {
                right: 0;
                left: auto;
            }
        }
    }
}

.pie{
    display: inline-block;
    position: relative;
    text-align: center;
    width: 100%;
    > .pie-value{
        display: block;
        position: absolute;
        font-size: 14px;
        height: 40px;
        top: 50%;
        left: 0;
        right: 0;
        margin-top: -20px;
        line-height: 40px;
    }
}
.block-content {
    &.child {
        display: flex;
        flex-wrap: wrap;
    }
    > .progress {
        height: 44px;
        >.progress-bar {
            line-height: 28px;
            text-align: left;
            padding: 8px 16px;
        }
    }
    &:after {
        display:table;
        content: '';
        clear: both;
    }
} 